import React from "react";
import { graphql, Link } from "gatsby";

import * as styles from "./index.module.scss";
import { MetaProps } from "~components/templates/seo";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { KenroAchievements } from "~components/kenro/achievements";

type Props = {
  data: GatsbyKenroPricingQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title: "料金プラン | KENRO",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description: "KENRO (ケンロー) の料金プランについて説明しています。",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <div className={styles.Pricing}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h1 className={styles.Pricing_Heading}>料金</h1>
          <p className={styles.Pricing_Description}>
            Web脆弱性基礎編コースが基本となる学習コンテンツです。
            <br className={styles.Pricing_NewLine} />
            必要に応じてGraphQL・JWT・WebSocketコースもご利用ください。
          </p>
          <div className={styles.Pricing_Plan}>
            <div className={styles.Pricing_PlanTableWrapper}>
              <table className={styles.Pricing_Table1}>
                <thead>
                  <tr>
                    <th />
                    <th>コース内容</th>
                    <th className={styles.Pricing_PlanTable}>料金(税抜)</th>
                    <th className={styles.Pricing_PlanTable}>受講期間</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Web脆弱性
                      <br />
                      基礎編コース
                    </td>
                    <td>
                      <ul>
                        <li>SQL Injection</li>
                        <li>Cross-Site Scripting (XSS)</li>
                        <li>Cross-Site Request Forgery (CSRF)</li>
                        <li>Insecure Deserialization</li>
                        <li>Directory Traversal</li>
                        <li>OS Command Injection</li>
                        <li>XML eXternal Entity (XXE)</li>
                        <li>Header Injection</li>
                        <li>Open Redirection</li>
                        <li>Clickjacking</li>
                      </ul>
                    </td>
                    <td>
                      80,000円* / 人
                      <br />
                      <span className={styles.Pricing_PlanTableAsterisk}>
                        *次表参照
                      </span>
                    </td>
                    <td>12ヶ月間</td>
                  </tr>
                  <tr>
                    <td>GraphQLコース</td>
                    <td>GraphQLの基礎とセキュリティ</td>
                    <td>20,000円 / 人</td>
                    <td>12ヶ月間</td>
                  </tr>
                  <tr>
                    <td>JWTコース</td>
                    <td>JWTの基礎とセキュリティ</td>
                    <td>20,000円 / 人</td>
                    <td>12ヶ月間</td>
                  </tr>
                  <tr>
                    <td>WebSocketコース</td>
                    <td>WebSocketの基礎とセキュリティ</td>
                    <td>20,000円 / 人</td>
                    <td>12ヶ月間</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className={styles.Pricing_Description}>
            Web脆弱性基礎編コースは、累積購入数に応じて受講者あたりの料金が変動します。
            <br className={styles.Pricing_NewLine} />
            追加購入いただく際も、過去のご購入数と追加のご購入数の合計により料金を決定します。
            <br className={styles.Pricing_NewLine} />
            例えば、初回に40名のご発注の場合、40名 × 76,000円 と計算されます。
            <br className={styles.Pricing_NewLine} />
            更に追加で20名のご発注の場合はフェーズ4を適用し、20名 × 74,000円
            と計算されます。
          </p>
          <div className={styles.Pricing_Plan}>
            <div className={styles.Pricing_PlanTableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>フェーズ</th>
                    <th>累積購入数</th>
                    <th>1名あたりの料金（税抜）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>1~10名</td>
                    <td>80,000円</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>11~30名</td>
                    <td>78,000円</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>31~50名</td>
                    <td>76,000円</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>51~70名</td>
                    <td>74,000円</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>71~100名</td>
                    <td>72,000円</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>101名以上</td>
                    <td>70,000円</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.Pricing_QuestionContainer}>
            <h3 className={styles.Pricing_QuestionHeader}>
              料金に関する詳細・FAQ
            </h3>
            <h4 className={styles.Pricing_QuestionQ}>
              累積購入数はどう計算しますか？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              ご請求時点(初回発注以外は当月末日締め)での累計の受講者数を”累積購入数”としています。
              <br className={styles.Pricing_NewLine} />
              年度内の複数回の追加購入でも、年度をまたいでの複数回の追加購入でも、全て累積して計算いたします。
              <br className={styles.Pricing_NewLine} />
              12ヶ月の受講期限が終了した受講者も累積購入数にカウントされます。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              基礎編コース以外も累積購入にカウントされますか？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              されません。
              <br className={styles.Pricing_NewLine} />
              累積購入数の対象はWeb脆弱性基礎編コースのみとなります。
              <br className={styles.Pricing_NewLine} />
              GraphQLコース、WebSocketコース、JSONWeb
              Tokenコースは対象外ですのでご注意ください。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              累積購入数はどこから確認できますか？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              管理者の方であれば、管理画面からいつでもご確認いただけます。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              受講者アカウントの追加購入方法は？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              受講コースの追加登録(購入)方法は？
              <br className={styles.Pricing_NewLine} />
              管理画面から管理者様ご自身で登録可能です。
              <br className={styles.Pricing_NewLine} />
              初回に登録いただいたコース以外も、こちらから追加登録が可能です。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              初回申し込み〜初回請求までの流れは？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              お申込み後３営業日以内に、管理者の方へ管理者アカウントを発行いたします。
              <br className={styles.Pricing_NewLine} />
              管理者アカウントを発行する同日にご請求書を発行いたします。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              追加の申し込み〜請求までの流れは？
            </h4>
            <p className={styles.Pricing_QuestionA}>
              管理画面からご自由に受講者にコースを登録可能です。事前に営業担当者に相談することもできます。
              <br className={styles.Pricing_NewLine} />
              コース登録によって料金が発生し、ご請求は当月末日締め翌月末日払いとなります。
            </p>
            <h4 className={styles.Pricing_QuestionQ}>
              お支払い方法について教えてください
            </h4>
            <p className={styles.Pricing_QuestionA}>
              銀行振込のみのご対応となります。
            </p>
          </div>

          <div className={styles.Pricing_ContactContainer}>
            <Link className={styles.Pricing_Contact} to="/kenro/contact">
              KENRO導入のお問い合わせ
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.PricingAchievement}>
        <p className={styles.PricingAchievement_Description}>
          大規模な新人研修からスタートアップ企業のチーム演習まで、組織規模を問わずご活用いただいています。
        </p>
        <div className={styles.PricingAchievement_Logos}>
          <KenroAchievements />
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

export const query = graphql`
  query KenroPricing {
    ok: file(relativePath: { eq: "kenro/ok.svg" }) {
      publicURL
    }
    ng: file(relativePath: { eq: "kenro/ng.svg" }) {
      publicURL
    }
    ogp: file(relativePath: { eq: "kenro/ogp.png" }) {
      publicURL
    }
  }
`;

export default Component;
