// extracted by mini-css-extract-plugin
export var KenroCommon2PaneSectionLayout = "index-module--KenroCommon2PaneSectionLayout--7tseD";
export var Pricing = "index-module--Pricing--3J6qH";
export var PricingAchievement = "index-module--PricingAchievement--y4CB-";
export var PricingAchievement_Description = "index-module--PricingAchievement_Description--IplG9";
export var PricingAchievement_Logos = "index-module--PricingAchievement_Logos--Xs0nJ";
export var Pricing_Contact = "index-module--Pricing_Contact--oht1Y";
export var Pricing_ContactContainer = "index-module--Pricing_ContactContainer--Wyzbg";
export var Pricing_Description = "index-module--Pricing_Description--0sf3H";
export var Pricing_Heading = "index-module--Pricing_Heading--kOQrV";
export var Pricing_NewLine = "index-module--Pricing_NewLine--3JakU";
export var Pricing_Plan = "index-module--Pricing_Plan--RmFxL";
export var Pricing_PlanTable = "index-module--Pricing_PlanTable--AE+D9";
export var Pricing_PlanTableAsterisk = "index-module--Pricing_PlanTableAsterisk--08Be7";
export var Pricing_PlanTableWrapper = "index-module--Pricing_PlanTableWrapper--Il8XI";
export var Pricing_QuestionA = "index-module--Pricing_QuestionA--d5-sK";
export var Pricing_QuestionContainer = "index-module--Pricing_QuestionContainer---xrZH";
export var Pricing_QuestionHeader = "index-module--Pricing_QuestionHeader--4oIXT";
export var Pricing_QuestionQ = "index-module--Pricing_QuestionQ--GcMvb";
export var Pricing_Table1 = "index-module--Pricing_Table1--ZSkoJ";
export var sp = "index-module--sp--pp7gH";